import React from "react";
import styled, { css } from "styled-components";

const DividerSvg = styled.svg`
  display: block;
  bottom: -1px;
  left: 0;
  right: 0;
  width: 102%;
  margin-left: -1%;
  background-color: transparent;
  pointer-events: none;
  user-select: none;
  vertical-align: middle;
  overflow: hidden;
`;
const footerFills = {
  waveOne: "rgb(244, 246, 249)",
  waveTwo: "rgb(247, 249, 251)",
  waveThree: "rgb(251, 251, 252)",
};

const errorFills = {
  waveOne: "rgb(195, 91, 95)",
  waveTwo: "rgb(213, 144, 172)",
  waveThree: "rgb(222, 200, 225)",
  waveFour: "rgb(251, 251, 252)",
};

const WaveSmooth = (props) => (
  <DividerSvg
    {...props}
    viewBox="0 0 1440 120"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1440 21.21V120H0V21.21C120 35.07 240 42 360 42s240-6.93 360-20.79c88.328-8.794 154.574-14.333 198.738-16.618A3120.562 3120.562 0 0 1 1080 .42c120 0 240 6.93 360 20.79z" />
  </DividerSvg>
);

const WaveSmooth2 = (props) => (
  <DividerSvg
    {...props}
    viewBox="0 0 1920 157.327"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M303.719 110.734a3330.42 3330.42 0 0 0 168.09 4.234 3320.491 3320.491 0 0 0 159.313-3.813c135.609-6.535 268.979-21.525 396.41-44.547C1296.81 17.949 1525.27 3.983 1669.51.833c151.12-3.304 243.74 4.253 250.49 4.822v151.672H0v-77.97c12.939 2.334 128.5 22.508 303.719 31.377z" />
  </DividerSvg>
);

const WaveRough = (props) => (
  <DividerSvg
    {...props}
    viewBox="0 0 1920 240"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0c62.028 23.301 207.726 31.062 355.345 10.956 194.93-26.548 329.274 58.551 412.36 95.275 113.991 50.379 161.026 36.177 242.491 47.604 81.464 11.428 80.28 30.32 167.409 35.339 99.802 5.746 258.527-51.115 385.457-10.565 156.954 50.136 300.814-6.06 356.938 1.225V240H0V0z" />
  </DividerSvg>
);

const WaveRoaring = (props) => (
  <DividerSvg
    {...props}
    viewBox="0 0 2000 242"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2000 75.463c-230.631 73.429-431.99 108.499-604.064 105.196-293.434-5.632-485.998-145.823-954.537-150.568C272.956 28.385 125.825 50.689 0 96.996V242h2000V75.463z" />
  </DividerSvg>
);

// viewBox="0 0 1600 173"
//<path d="M1665.731 145.823c-115.382 81.947-257.301-50.789-507.113-94.011C854.859-.745 794.065 148.685 426.121 93.955 334.889 80.383 279.017 62.33 183.4 75.585 20.51 98.165-64.362 184.803-137.338 140.42c-40.128-24.406-64.669-81.136-48.761-103.736 34.158-48.53 217.973 112.638 473.523 108.058 233.32-4.18 251.403-141.599 475.689-143.718 226.76-2.141 245.44 137.972 501.695 148.04 232.694 9.144 426.896-98.152 443.183-61.593 6.454 14.485-19.273 42.026-42.26 58.352z" />
const LinesWavyIntersecting = (props) => (
  <DividerSvg
    {...props}
    viewBox="0 0 1440 70"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 30L30 29.5C60 29 120 28 180 23.5C240 19 300 11 360 15.8C420 20.7 480 38.3 540 44.8C600 51.3 660 46.7 720 48.2C780 49.7 840 57.3 900 57.3C960 57.3 1020 49.7 1080 42.8C1140 36 1200 30 1260 23.5C1320 17 1380 10 1410 6.5L1440 3L1440 71L1410 71C1380 71 1320 71 1260 71C1200 71 1140 71 1080 71C1020 71 960 71 900 71C840 71 780 71 720 71C660 71 600 71 540 71C480 71 420 71 360 71C300 71 240 71 180 71C120 71 60 71 30 71L0 71Z" />
  </DividerSvg>
);

const WaveWobbly = (props) => (
  <DividerSvg
    {...props}
    viewBox="0 0 2463.834 324"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M449.979 194.247c116.719-64.927 202.059-74.405 277.129 8.894S854.826 70.9 945.537 63.955s132.559 75.705 222.363 55.469 213.11-152.42 385.11-111.672c172 40.747 160.191 200.818 264.359 170.945 104.167-29.873 125.46-98.789 203.364-79.848 64.65 15.721 100.484 121.061 177.736 86.945C2451.397 74.1 2463.834 324 2463.834 324H0s63.247-148.374 251.034-95.148c91.928 26.056 122.236 8.066 198.945-34.605z" />
  </DividerSvg>
);

const WaveSwaying = (props) => (
  <DividerSvg
    {...props}
    viewBox="0 0 1440 94"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 .91c37.102-3.789 72.529 8.207 112.12 35.083 104.792 71.133 186.932 18.896 299.41-18.297 112.476-37.193 128.544 64.109 218.349 43.87 89.805-20.238 155.519-61.91 327.519-21.16s253.141 66.825 357.309 36.95c49.177-14.104 85.113-37.259 125.293-57.88V94H0V.91z" />
  </DividerSvg>
);

const WaveFooter = (props) => (
  <DividerSvg
    {...props}
    viewBox="0 0 1920 250"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      css={css`
        fill: ${footerFills.waveOne};
      `}
      d="M1920 250H0V0s126.707 78.536 349.975 80.05c177.852 1.203 362.805-63.874 553.803-63.874 290.517 0 383.458 57.712 603.992 61.408 220.527 3.696 278.059-61.408 412.23-17.239"
    />
    <path
      css={css`
        fill: ${footerFills.waveTwo};
      `}
      d="M1920 144s-467.917 116.857-1027.243-17.294C369.986 1.322 0 45.578 0 45.578V250h1920V144z"
    />
    <path
      css={css`
        fill: ${footerFills.waveThree};
      `}
      d="M0 195.553s208.547-75.581 701.325-20.768c376.707 41.908 520.834-67.962 722.545-67.962 222.926 0 311.553 83.523 496.129 86.394V250H0v-54.447z"
    />
  </DividerSvg>
);

const WaveError = (props) => {
  return (
    <DividerSvg
      {...props}
      viewBox="0 0 1920 540"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        css={css`
          fill: ${errorFills.waveOne};
        `}
      >
        <animate
          repeatCount="indefinite"
          attributeName="d"
          dur="5s"
          values="M0 166L101 118L202 133L303 111L404 106L505 124L606 63L707 166L808 156L909 172L1011 162L1112 91L1213 78L1314 160L1415 144L1516 156L1617 145L1718 108L1819 75L1920 148L1920 541L1819 541L1718 541L1617 541L1516 541L1415 541L1314 541L1213 541L1112 541L1011 541L909 541L808 541L707 541L606 541L505 541L404 541L303 541L202 541L101 541L0 541Z;
          M0 172L101 68L202 102L303 149L404 106L505 169L606 86L707 123L808 171L909 155L1011 110L1112 156L1213 139L1314 134L1415 160L1516 80L1617 141L1718 93L1819 64L1920 71L1920 541L1819 541L1718 541L1617 541L1516 541L1415 541L1314 541L1213 541L1112 541L1011 541L909 541L808 541L707 541L606 541L505 541L404 541L303 541L202 541L101 541L0 541Z;
          M0 95L101 173L202 88L303 66L404 123L505 85L606 129L707 72L808 114L909 140L1011 127L1112 135L1213 105L1314 134L1415 94L1516 160L1617 104L1718 75L1819 107L1920 109L1920 541L1819 541L1718 541L1617 541L1516 541L1415 541L1314 541L1213 541L1112 541L1011 541L909 541L808 541L707 541L606 541L505 541L404 541L303 541L202 541L101 541L0 541Z;
          M0 166L101 118L202 133L303 111L404 106L505 124L606 63L707 166L808 156L909 172L1011 162L1112 91L1213 78L1314 160L1415 144L1516 156L1617 145L1718 108L1819 75L1920 148L1920 541L1819 541L1718 541L1617 541L1516 541L1415 541L1314 541L1213 541L1112 541L1011 541L909 541L808 541L707 541L606 541L505 541L404 541L303 541L202 541L101 541L0 541Z;"
        />
      </path>

      <path
        css={css`
          fill: ${errorFills.waveTwo};
        `}
      >
        <animate
          repeatCount="indefinite"
          attributeName="d"
          dur="5s"
          values="M0 202L101 240L202 213L303 230L404 218L505 170L606 269L707 219L808 284L909 252L1011 200L1112 174L1213 279L1314 225L1415 175L1516 231L1617 197L1718 260L1819 244L1920 258L1920 541L1819 541L1718 541L1617 541L1516 541L1415 541L1314 541L1213 541L1112 541L1011 541L909 541L808 541L707 541L606 541L505 541L404 541L303 541L202 541L101 541L0 541Z;
          M0 167L101 200L202 214L303 183L404 275L505 178L606 176L707 211L808 193L909 243L1011 227L1112 206L1213 265L1314 223L1415 279L1516 209L1617 209L1718 167L1819 227L1920 188L1920 541L1819 541L1718 541L1617 541L1516 541L1415 541L1314 541L1213 541L1112 541L1011 541L909 541L808 541L707 541L606 541L505 541L404 541L303 541L202 541L101 541L0 541Z;
          M0 240L101 187L202 167L303 187L404 205L505 178L606 184L707 249L808 229L909 174L1011 275L1112 259L1213 284L1314 169L1415 258L1516 202L1617 280L1718 240L1819 259L1920 183L1920 541L1819 541L1718 541L1617 541L1516 541L1415 541L1314 541L1213 541L1112 541L1011 541L909 541L808 541L707 541L606 541L505 541L404 541L303 541L202 541L101 541L0 541Z;
          M0 202L101 240L202 213L303 230L404 218L505 170L606 269L707 219L808 284L909 252L1011 200L1112 174L1213 279L1314 225L1415 175L1516 231L1617 197L1718 260L1819 244L1920 258L1920 541L1819 541L1718 541L1617 541L1516 541L1415 541L1314 541L1213 541L1112 541L1011 541L909 541L808 541L707 541L606 541L505 541L404 541L303 541L202 541L101 541L0 541Z;"
        />
      </path>
      <path
        css={css`
          fill: ${errorFills.waveThree};
        `}
      >
        <animate
          repeatCount="indefinite"
          attributeName="d"
          dur="5s"
          values="M0 311L101 383L202 356L303 313L404 343L505 377L606 325L707 299L808 358L909 350L1011 282L1112 283L1213 272L1314 288L1415 363L1516 317L1617 308L1718 334L1819 363L1920 384L1920 541L1819 541L1718 541L1617 541L1516 541L1415 541L1314 541L1213 541L1112 541L1011 541L909 541L808 541L707 541L606 541L505 541L404 541L303 541L202 541L101 541L0 541Z;
          M0 353L101 349L202 338L303 348L404 324L505 353L606 294L707 295L808 299L909 380L1011 371L1112 286L1213 359L1314 275L1415 317L1516 346L1617 332L1718 385L1819 381L1920 339L1920 541L1819 541L1718 541L1617 541L1516 541L1415 541L1314 541L1213 541L1112 541L1011 541L909 541L808 541L707 541L606 541L505 541L404 541L303 541L202 541L101 541L0 541Z;
          M0 357L101 317L202 334L303 342L404 327L505 299L606 328L707 353L808 371L909 300L1011 384L1112 291L1213 289L1314 299L1415 316L1516 302L1617 317L1718 291L1819 275L1920 348L1920 541L1819 541L1718 541L1617 541L1516 541L1415 541L1314 541L1213 541L1112 541L1011 541L909 541L808 541L707 541L606 541L505 541L404 541L303 541L202 541L101 541L0 541Z;
          M0 311L101 383L202 356L303 313L404 343L505 377L606 325L707 299L808 358L909 350L1011 282L1112 283L1213 272L1314 288L1415 363L1516 317L1617 308L1718 334L1819 363L1920 384L1920 541L1819 541L1718 541L1617 541L1516 541L1415 541L1314 541L1213 541L1112 541L1011 541L909 541L808 541L707 541L606 541L505 541L404 541L303 541L202 541L101 541L0 541Z;"
        />
      </path>
      <path
        css={css`
          fill: ${errorFills.waveFour};
        `}
      >
        <animate
          repeatCount="indefinite"
          attributeName="d"
          dur="5s"
          values="M0 414L101 480L202 397L303 442L404 446L505 481L606 449L707 428L808 385L909 477L1011 439L1112 479L1213 480L1314 454L1415 473L1516 431L1617 377L1718 482L1819 410L1920 484L1920 541L1819 541L1718 541L1617 541L1516 541L1415 541L1314 541L1213 541L1112 541L1011 541L909 541L808 541L707 541L606 541L505 541L404 541L303 541L202 541L101 541L0 541Z;
          M0 398L101 438L202 377L303 427L404 449L505 406L606 386L707 414L808 480L909 453L1011 476L1112 403L1213 440L1314 399L1415 457L1516 449L1617 400L1718 388L1819 423L1920 382L1920 541L1819 541L1718 541L1617 541L1516 541L1415 541L1314 541L1213 541L1112 541L1011 541L909 541L808 541L707 541L606 541L505 541L404 541L303 541L202 541L101 541L0 541Z;
          M0 439L101 435L202 435L303 385L404 388L505 403L606 401L707 467L808 469L909 477L1011 435L1112 484L1213 468L1314 411L1415 381L1516 440L1617 451L1718 439L1819 459L1920 408L1920 541L1819 541L1718 541L1617 541L1516 541L1415 541L1314 541L1213 541L1112 541L1011 541L909 541L808 541L707 541L606 541L505 541L404 541L303 541L202 541L101 541L0 541Z;
          M0 414L101 480L202 397L303 442L404 446L505 481L606 449L707 428L808 385L909 477L1011 439L1112 479L1213 480L1314 454L1415 473L1516 431L1617 377L1718 482L1819 410L1920 484L1920 541L1819 541L1718 541L1617 541L1516 541L1415 541L1314 541L1213 541L1112 541L1011 541L909 541L808 541L707 541L606 541L505 541L404 541L303 541L202 541L101 541L0 541Z;"
        />
      </path>
    </DividerSvg>
  );
};

const dividerColor = {
  bright: ({ theme }) => theme.colors.dividerColorBright,
  light: ({ theme }) => theme.colors.dividerColorLight,
  lighter: ({ theme }) => theme.colors.dividerColorLighter,
  dark: ({ theme }) => theme.colors.dividerColorDark,
  gray: ({ theme }) => theme.colors.dividerColorGray,
  decoration: ({ theme }) => theme.colors.decorationColor,
};

const WaveDividerOne = styled(WaveSmooth)`
  margin-bottom: -1px;
  fill: ${({ bgColor }) =>
    bgColor ? dividerColor[bgColor] : dividerColor.light};
`;

const WaveDividerTwo = styled(WaveRough)`
  fill: ${({ bgColor }) =>
    bgColor ? dividerColor[bgColor] : dividerColor.light};
`;

const WaveDividerThree = styled(WaveSmooth2)`
  fill: ${({ bgColor }) =>
    bgColor ? dividerColor[bgColor] : dividerColor.light};
  position: relative;
  z-index: 1;
`;

const WavyLineDivider = styled(LinesWavyIntersecting)`
  fill: ${({ bgColor }) =>
    bgColor ? dividerColor[bgColor] : dividerColor.lighter};
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
`;

const WaveDividerFour = styled(WaveRoaring)`
  fill: ${({ bgColor }) =>
    bgColor ? dividerColor[bgColor] : dividerColor.light};
`;

const WaveDividerWob = styled(WaveWobbly)`
  fill: ${({ bgColor }) =>
    bgColor ? dividerColor[bgColor] : dividerColor.light};
`;

const WaveDividerSway = styled(WaveSwaying)`
  fill: ${({ bgColor }) =>
    bgColor ? dividerColor[bgColor] : dividerColor.light};
`;

export {
  WaveDividerOne,
  WaveDividerTwo,
  WaveDividerThree,
  WaveDividerFour,
  WavyLineDivider,
  WaveFooter,
  WaveDividerWob,
  WaveDividerSway,
  WaveError,
};
