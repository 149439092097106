import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import ms from "../../util/ms";

const baseHeadingStyles = css`
  margin-top: 0;
  margin-bottom: ${({ compact }) => (compact ? 0 : "0.5em")};
  font-weight: 500;
`;

const H1 = styled.h1`
  ${baseHeadingStyles};
  font-size: ${ms(6)};
`;

const H2 = styled.h2`
  ${baseHeadingStyles};
  font-size: ${ms(5)};
`;

const H3 = styled.h3`
  ${baseHeadingStyles};
  font-size: ${ms(4)};
  ${({ theme, bright }) => bright && `color: ${theme.colors.brighterColor};`};
  ${({ flex }) =>
    flex &&
    css`
      display: flex;
      align-items: center;
      padding: 0 0 1em;
    `};
  ${({ subGrid }) =>
    subGrid &&
    css`
      display: flex;
      align-items: center;
      padding-left: 0.25em;
      margin-bottom: -0.5em;
    `}

  svg {
    width: 1.25em;
    height: 1.25em;
    margin-right: 0.5em;
  }
`;

const H4 = styled.h4`
  ${baseHeadingStyles};
  font-size: ${ms(3)};
`;

const Headline = styled(H2)`
  font-weight: 700;
  margin-bottom: 0.8em;
  transition: color 100ms ease-in-out;

  ${({ hero }) =>
    hero &&
    css`
      font-size: ${ms(5)};
      letter-spacing: -0.02em;
      margin-left: -0.5em;
      margin-right: -0.5em;
      text-align: center;

      ${({ theme }) => theme.media.tabletPortrait`
      font-size: ${ms(7)};
      letter-spacing: initial;
      margin-left: initial;
      margin-right: initial;
      text-align: initial;
    `};

      ${({ theme }) => theme.media.desktop`
      font-size: ${ms(8)};
      letter-spacing: initial;
      margin-left: initial;
      margin-right: initial;
      text-align: initial;
    `};
    `}
  ${({ herosub }) =>
    herosub &&
    css`
      font-size: ${ms(3)};
      letter-spacing: -0.035em;
      margin-left: -0.6em;
      margin-right: -0.6em;
      margin-bottom: 1em;

      ${({ theme }) => theme.media.tabletPortrait`
      font-size: ${ms(5)};
      letter-spacing: initial;
      margin-left: initial;
      margin-right: initial;
      margin-bottom: 0.8em;
    `};

      ${({ theme }) => theme.media.desktop`
      font-size: ${ms(6)};
      letter-spacing: initial;
      margin-left: initial;
      margin-right: initial;
      margin-bottom: 0.8em;
    `};
    `}

  ${({ large }) =>
    large &&
    css`
      font-size: ${ms(6)};

      ${({ theme }) => theme.media.tabletLandscape`
      font-size: ${ms(7)};
    `};

      ${({ theme }) => theme.media.desktop`
      font-size: ${ms(8)};
    `};
    `}
`;

const propTypes = {
  hasBottomMargin: PropTypes.bool,
};

const defaultProps = {
  hasBottomMargin: false,
};

H1.propTypes = propTypes;
H1.defaultProps = defaultProps;

H2.propTypes = propTypes;
H2.defaultProps = defaultProps;

H3.propTypes = propTypes;
H3.defaultProps = defaultProps;

H4.propTypes = propTypes;
H4.defaultProps = defaultProps;

export { H1, H2, H3, H4, Headline };
