import styled from "styled-components";

const ImageContainer = styled.div`
  position: relative;
  margin: 0 auto;
  width: 70vw;
  ${({ theme }) => theme.media.tabletLandscape`
    width: 80%;
  `};
`;

export default ImageContainer;
