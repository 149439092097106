import styled, { css } from "styled-components";

const Visualization = styled.div`
  position: relative;
  width: 100%;
  order: ${({ reverseRowOrder }) => (reverseRowOrder ? 0 : 1)};

  ${({ theme }) => theme.media.phoneLandscape`
    width: 80%;
  `};

  ${({ theme, reverseRowOrder }) => theme.media.tabletLandscape`
    width: 100%;
    order: ${reverseRowOrder ? 1 : 0};
  `};

  ${({ testimonials }) =>
    testimonials &&
    css`
      order: 0;
      display: grid;
      grid-template-columns: 1fr;

      ${({ theme }) => theme.media.tabletLandscape`
        grid-template-columns: repeat(2, 1fr);
      `};

      gap: 4rem 3.5rem;
    `}

  ${({ subGrid }) =>
    subGrid &&
    css`
      order: 0;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-areas:
        "german" "reel1" "reel2"
        "english" "reel3" "demo1" "demo2";

      ${({ theme }) => theme.media.tabletLandscape`
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas: 
          "german english" "reel1 reel3" "reel2 demo1" ". demo2";
      `};

      gap: 2rem 4.5rem;
    `}
`;

const MobileVisualization = styled(Visualization)`
  margin-bottom: 1.8rem;
  ${({ theme }) => theme.media.tabletLandscape`
    display: none;
  `};
  ${({ theme }) => theme.media.phoneLandscape`
    width: 100%;
  `};
`;
const DesktopVisualization = styled(Visualization)`
  display: none;
  ${({ theme }) => theme.media.tabletLandscape`
    display: unset;
    top: -2.8em;
  `};
`;

export default Visualization;

export { MobileVisualization, DesktopVisualization };
