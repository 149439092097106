import styled, { css } from "styled-components";

const Text = styled.div`
  color: ${({ theme }) => theme.colors.baseColor};
  ${({ theme, bright }) => bright && `color: ${theme.colors.brighterColor};`};

  text-align: center;

  ${({ theme, verticalOnly }) => theme.media.tabletLandscape`
    text-align: ${!verticalOnly && "start"};
  `};

  ${({ quote }) =>
    quote &&
    css`
      font-size: 1.1;
      text-align: right;
      font-style: italic;
      opacity: 0.7;
      margin-top: 0.5em;
      ${({ theme }) => theme.media.tabletLandscape`
        text-align: right;
        font-size: 1.35rem;
        margin-right: 0em;
        margin-top: 0.2em;
        &:before {
          content: "— ";
        }
      `};

      ${({ theme }) => theme.media.desktop`
        
      `};
    `}
  ${({ reverseRowOrder }) =>
    reverseRowOrder &&
    `
    order: 1;
    align-self: flex-start;
    padding-left: 2em;
  `};
`;

const Pre = styled.pre`
  background-color: ${({ theme }) => theme.backgroundColors.codeColor};
  overflow: auto;
  white-space: pre;
`;

const Code = styled.code`
  font-family: ${({ theme }) => theme.font.monospace};
  font-weight: 500;
  font-size: 0.85em;
  padding: 0.2em 0.4em;
  background-color: ${({ theme }) => theme.backgroundColors.codeColor};
  border-radius: 4px;

  ${Pre} & {
    display: block;
    padding: 1em;
    font-size: 0.9375em;
  }
`;

export { Pre, Code };
export default Text;
