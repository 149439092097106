import styled, { css } from "styled-components";
import em from "../../util/em";

const Content = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 2.5em 1.5em;
  position: relative;

  ${({ theme }) => theme.media.desktop`
		max-width: ${em(theme.media.breakpoints.desktopLowerBoundary)};
	`};

  ${({ theme }) => theme.media.tabletLandscape`
    padding-top: 5em;
    padding-bottom: 5em;
    
    ${({ spaceBottom }) => spaceBottom && `padding-bottom: 8em;`};
    ${({ noSpaceBottom }) =>
      noSpaceBottom &&
      css`
        padding-bottom: 2em;
        position: relative;
        z-index: 1;
      `};
    ${({ reducedTopPadding }) => reducedTopPadding && `padding-top: 0.25em;`}
  `};
`;

export default Content;
