import styled from "styled-components";

const Section = styled.section`
  position: relative;
  top: ${({ gapWidth }) => gapWidth && `-${gapWidth}px`};
  background-color: ${({ bgColor }) => bgColor};
  overflow: hidden;
`;

export default Section;
