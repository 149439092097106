import styled, { css } from "styled-components";
import ms from "../../util/ms";

const Subline = styled.p`
  position: relative;
  z-index: 1;
  ${({ theme, bright }) => bright && `color: ${theme.colors.brighterColor};`};
  font-size: ${ms(1)};
  transition: color 1ms ease-in-out;
  margin-top: 0;
  margin-bottom: ${({ quote }) => (quote ? "0" : "1em")};

  ${({ theme }) => theme.media.phoneLandscape`
    font-size: ${ms(2)};
  `};
  ${({ subGrid }) =>
    subGrid &&
    css`
      padding-left: 0.25em;
      margin-bottom: -1em;
    `}
  ${({ centered }) => centered && `text-align: center;`}

  ${({ quote }) =>
    quote &&
    css`
      &:before {
        content: ",,";
        letter-spacing: -0.07em;
        position: absolute;
        top: -1.15em;
        left: -0.25em;
        font-size: 5em;
        font-family: "Times New Roman", serif;
        color: ${({ theme }) => theme.colors.dividerColorLighter};
        opacity: 0.2;
        z-index: -1;
        ${({ theme }) => theme.media.tabletLandscape`
          font-size: 8em;
        `};
      }
    `}

  b {
    font-weight: 600;
    ${({ theme }) => `color: ${theme.colors.highlightBaseColor};`}
  }
`;

const ContactSubline = styled(Subline)`
  ${({ theme }) => theme.media.tabletLandscape`
    padding-left: 7rem;
    padding-right: 7rem;
  `};
  &:first-of-type {
    margin-bottom: 2em;
  }
`;

export default Subline;
export { ContactSubline };
